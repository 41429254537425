import React from 'react';
import {
  Route, Routes, Navigate, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import s from './Wrapper.module.scss';
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import Welcome from '../../pages/Welcome/Welcome';
import Interview from '../../pages/Interview/Interview';
import FinalPage from '../../pages/FinalPage/FinalPage';
import PatientCases from '../../pages/PatientCases/PatientCases';
import { selectCurrentInterview, selectInterviewId } from '../../redux/slices/currentInterviewSlice';
import { selectEvent } from '../../redux/slices/eventSlice';
import { getisInterviewPage } from '../../helpers/utils';

const Wrapper = () => {
  const { token: eventId, isOnlyCase } = useSelector(state => state.event);
  const { pathname } = useLocation();
  const isInterviewPage = getisInterviewPage(pathname);
  return (
    <div className={s.wrapperContainer}>
      <div className={s.wrapperOuter}>
        <div className={s.wrapperHeader}>
          {isInterviewPage && <Header />}
        </div>
        <div className={classNames(s.wrapperInner,
          { [s.welcomeWrapper]: pathname === '/' })}>
          { isInterviewPage && <div className={s.wrapperMenu}>
            <Menu />
          </div> }
          <div className={s.wrapper}>
            <div className={s.wrapperRoutes}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <OnlyCase>
                      <EventExist>
                        <Welcome />
                      </EventExist>
                    </OnlyCase>
                  }
                />
                <Route
                  path="/interviews"
                  element={
                    <OnlyCase>
                      <EventExist>
                        <EventNotFinishedExist>
                          <PatientCases />
                        </EventNotFinishedExist>
                      </EventExist>
                    </OnlyCase>
                  }
                />
                <Route
                  path="/interviews/:id"
                  element={
                    <EventExist>
                      <InterviewExist>
                        <FinishNotExist>
                          <ShareableExist>
                            <Interview />
                          </ShareableExist>
                        </FinishNotExist>
                      </InterviewExist>
                    </EventExist>
                  }
                />
                <Route
                  path="/interviews/:id/finish"
                  element={
                    <EventExist>
                      <FinishExist>
                        <ShareableExist>
                          <FinalPage />
                        </ShareableExist>
                      </FinishExist>
                    </EventExist>
                  }
                />
                <Route
                  path="/notFound"
                  element={
                    !eventId && !isOnlyCase ? (
                      <Welcome notFound />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route path="*" element={<Navigate to="/notFound" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function OnlyCase({ children }) {
  const isOnlyCase = useSelector(state => state.event.isOnlyCase);
  const { shareable, id, isView } = useSelector(state => state.currentInterview);

  if (isOnlyCase && (shareable || isView)) return <Navigate to={`/interviews/${id}`} />;
  return children;
}

function EventExist({ children }) {
  const { token: eventId, isOnlyCase } = useSelector(state => state.event);

  if (!eventId && !isOnlyCase) return <Navigate to='/notFound' />;
  return children;
}

function EventNotFinishedExist({ children }) {
  const event = useSelector(selectEvent);

  if (event.finished) return <Navigate to={'/'} />;
  return children;
}

function InterviewExist({ children }) {
  const interviewId = useSelector(selectInterviewId);

  if (!interviewId) return <Navigate to='/' />;
  return children;
}

function FinishNotExist({ children }) {
  const interview = useSelector(selectCurrentInterview);

  if (interview.finished) return <Navigate to={`/interviews/${interview.id}/finish`} />;
  return children;
}

function FinishExist({ children }) {
  const interview = useSelector(selectCurrentInterview);

  if (!interview.finished) return <Navigate to={`/interviews/${interview.id}`} />;
  return children;
}

function ShareableExist({ children }) {
  const { isOnlyCase } = useSelector(state => state.event);
  const { shareable, isView } = useSelector(state => state.currentInterview);

  if (isOnlyCase && !shareable && !isView) return <Navigate to='/' />;
  return children;
}

export default Wrapper;
