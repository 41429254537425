import { createSlice } from '@reduxjs/toolkit';
import { INTERVIEW_TABS_CHAPTERS, INTERVIEW_TABS_KEYS } from '../../constants/names';
import { EXAMINATION_TYPES } from '../../constants/physicalConstants';

const { PHYSICAL, ADDITIONAL } = EXAMINATION_TYPES;
const { INTRODUCTION } = INTERVIEW_TABS_KEYS;

const initialState = {
  id: null,
  activeTab: INTERVIEW_TABS_CHAPTERS.INTRODUCTION,
  isNextLoading: false,
  isImageOpen: false,
  isImagesOpen: false,
  isVideoOpen: false,
  isAllFinished: false,
  isPollResultLoading: false,
  isPollModalActive: false,
  translation: {},
  keyWords: {},
  chatData: [],
  clientsConnected: 0,
  pollingIsOpen: false,
  pollingData: [],
};

const pollTemplateData = {
  results: [],
  noOfVotes: 0,
  started: false,
  showPoll: false,
  finished: false,
  pollIds: [],
  timer: null,
};

export const currentInterviewSlice = createSlice({
  name: 'currentInterview',
  initialState,
  reducers: {
    setInterview: (state, { payload }) => ({ ...state, ...payload }),
    setPollingData: (state, { payload }) => {
      payload.sheetContent.forEach((chapter) => {
        state.pollingData.push({
          id: chapter.id,
          title: chapter.title,
          ...pollTemplateData,
        });
      });
    },
    setClientsConnected: (state, { payload }) => {
      state.clientsConnected = payload.client;
    },
    setPollingStatus: (state, { payload }) => {
      state.pollingIsOpen = payload;
    },
    setActivePollTimer: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          poll.timer = payload.activeTimer;
        }
        return poll;
      });
    },
    setPollingBatchId: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          poll.pollIds.push(payload.pollId);
        }
        return poll;
      });
    },
    setPollingChapterStatus: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          if (payload.type === 'close') {
            poll.finished = true;
            poll.started = false;
          } else if (payload.type === 'open') {
            poll.started = true;
            // poll.noOfVotes = 0;
          }
        }
        return poll;
      });
    },
    setPollResultLoading: (state, { payload }) => {
      state.isPollResultLoading = payload;
      document.body.classList.add('no-scroll');
    },
    setPollModalActive: (state, { payload }) => {
      state.isPollModalActive = payload;
      if (!state.isPollModalActive) document.body.classList.remove('no-scroll');
    },
    setPollResults: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          poll.results = payload.results;
          state.isPollResultLoading = false;
        }
        return poll;
      });
      state.isPollModalActive = true;
    },
    setVotesPolled: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          poll.noOfVotes = payload.poll_count;
        }
        return poll;
      });
    },
    resetPollConfig: (state, { payload }) => {
      state.pollingData.map((poll) => {
        if (poll.id === payload.id) {
          poll.results = [];
          poll.started = false;
          poll.finished = false;
        }
        return poll;
      });
    },
    resetInterview: () => initialState,
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      if (payload !== INTRODUCTION) {
        const sectionId = state.sheetContent.findIndex(e => e.title === payload);
        state.sheetContent[sectionId]['visited'] = true;
      }
    },
    setNextLoading: (state, { payload }) => {
      state.isNextLoading = payload;
    },
    setImageOpen: (state, { payload }) => {
      state.isImageOpen = payload;
    },
    setImagesOpen: (state, { payload }) => {
      state.isImagesOpen = payload;
    },
    setVideoOpen: (state, { payload }) => {
      state.isVideoOpen = payload;
    },
    setSelected: (state, { payload }) => {
      const {
        section, subSection, optionId, examination, operation, order,
      } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      if (optionId === 'all') {
        Object.values(state.sheetContent[sectionId].data.answers).forEach((category) => {
          category.forEach((el) => {
            el.selected = !!operation;
          });
        });
        state.sheetContent[sectionId].started = !!operation;
      } else {
        const dataFolder = examination || 'answers';
        const itemId = state.sheetContent[sectionId].data[dataFolder][subSection].findIndex(
          item => item.id === optionId,
        );
        if (itemId === -1) return;
        state.sheetContent[sectionId].data[dataFolder][subSection][itemId].selected = !!operation;
        if (order) {
          state.sheetContent[sectionId].data[dataFolder][subSection][itemId].order = order;
        }
        if (operation) {
          state.sheetContent[sectionId].started = true;
        } else {
          const allData = Object.values(state.sheetContent[sectionId].data[dataFolder]);
          let isItemSelected = false;
          for (let i = 0; i < allData.length; i += 1) {
            for (let j = 0; j < allData[i].length; j += 1) {
              if (allData[i][j].selected) {
                isItemSelected = true;
                break;
              }
            }
          }
          if (examination) {
            const otherExamination = examination === PHYSICAL ? ADDITIONAL : PHYSICAL;
            const otherData = Object.values(state.sheetContent[sectionId].data[otherExamination]);
            for (let i = 0; i < otherData.length; i += 1) {
              for (let j = 0; j < otherData[i].length; j += 1) {
                if (otherData[i][j].selected) {
                  isItemSelected = true;
                  break;
                }
              }
            }
          }
          state.sheetContent[sectionId].started = isItemSelected;
        }
      }
    },
    setRowSelected: (state, { payload }) => {
      const {
        section, optionId, operation, isNone,
      } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      if (optionId === 'all') {
        state.sheetContent[sectionId].data.answers.forEach((el) => {
          el.selected = !!operation;
        });
      } else {
        const itemId = state.sheetContent[sectionId].data.answers.findIndex(
          item => item.id === optionId,
        );
        if (itemId === -1) return;
        if (isNone && operation) {
          state.sheetContent[sectionId].data.answers.forEach((answer) => {
            answer.selected = false;
          });
        }
        state.sheetContent[sectionId].data.answers[itemId].selected = !!operation;
      }
      if (operation) {
        state.sheetContent[sectionId].started = true;
      } else {
        const allData = state.sheetContent[sectionId].data.answers;
        let isItemSelected = false;
        for (let i = 0; i < allData.length; i += 1) {
          if (allData[i].selected) {
            isItemSelected = true;
            break;
          }
        }
        if (state.sheetContent[sectionId].data.comment) {
          isItemSelected = true;
        }
        state.sheetContent[sectionId].started = isItemSelected;
      }
    },
    setSectionFinished: (state, { payload }) => {
      const { section, nextTab } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      state.activeTab = nextTab;
      state.sheetContent[sectionId].finished = true;
      if (nextTab) {
        const nextSectionId = state.sheetContent.findIndex(sheet => sheet.title === nextTab);
        if (nextSectionId === -1) return;
        state.sheetContent[nextSectionId].visited = true;
      }
    },
    setSectionStarted: (state, { payload }) => {
      const { section, operation } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      state.sheetContent[sectionId].started = operation;
    },
    setComment: (state, { payload }) => {
      const { section, operation, comment } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      const value = operation ? comment : '';
      state.sheetContent[sectionId].data.comment = value;
      if (operation) {
        state.sheetContent[sectionId].started = true;
      } else {
        const allData = state.sheetContent[sectionId].data.answers;
        let isItemSelected = false;
        for (let i = 0; i < allData.length; i += 1) {
          if (allData[i].selected) {
            isItemSelected = true;
            break;
          }
        }
        state.sheetContent[sectionId].started = isItemSelected;
      }
    },
    finishCase: (state) => {
      state.finished = true;
      state.isAllFinished = true;
    },
    dragQuestion: (state, { payload }) => {
      const { sourceI, destI } = payload;
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === activeTab);
      if (sectionId === -1) return;
      const orders = state.sheetContent[sectionId].data.orders
        || state.sheetContent[sectionId].data.answers.map(answer => answer.id);
      const questionsArr = [ ...orders ];
      const [ selectedRow ] = questionsArr.splice(sourceI, 1);
      questionsArr.splice(destI, 0, selectedRow);
      state.sheetContent[sectionId].data.orders = questionsArr;
    },
    toggleShareable: (state, { payload }) => {
      state.shareable = payload;
    },
    setCaseStarted: (state) => {
      state.started = true;
    },
    setChatData: (state, { payload }) => {
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(e => e.title === activeTab);
      if (state.sheetContent[sectionId].chatData === undefined) {
        state.sheetContent[sectionId].chatData = [];
      }
      const savedChats = state.sheetContent[sectionId].chatData;
      if (state.sheetContent[sectionId].chatData.length > 0) {
        state.sheetContent[sectionId].chatData = savedChats.filter(el => el.id !== payload.id);
      }
      if (state.sheetContent[sectionId].chatData.length === savedChats.length) {
        state.sheetContent[sectionId].chatData.push(payload);
      }
    },
  },
});

// ACTIONS
export const {
  setInterview,
  resetInterview,
  setActiveTab,
  setSelected,
  dragQuestion,
  setSectionStarted,
  setSectionFinished,
  setRowSelected,
  setComment,
  finishCase,
  setNextLoading,
  setPollingData,
  setImageOpen,
  setImagesOpen,
  setVideoOpen,
  toggleShareable,
  setCaseStarted,
  setChatData,
  setClientsConnected,
  setActivePollTimer,
  setPollingStatus,
  setPollingBatchId,
  setPollingChapterStatus,
  setPollResultLoading,
  setPollModalActive,
  setPollResults,
  setVotesPolled,
  resetPollConfig,
} = currentInterviewSlice.actions;

// SELECTORS
export const selectCurrentInterview = state => state.currentInterview;
export const selectInterviewId = state => state.currentInterview.id;
export const selectInterviewTranslationId = state => state.currentInterview.tId;
export const selectActiveTab = state => state.currentInterview.activeTab;
export const selectTranslation = state => state.currentInterview.translation;
export const selectKeywords = state => state.currentInterview.keyWords;
export const selectChatData = state => state.currentInterview.chatData;

export default currentInterviewSlice.reducer;
