import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import s from './Interview.module.scss';
import Heading from '../../components/Heading/Heading';
import { Button } from '../../components/UI';
import {
  resetPollConfig,
  setActivePollTimer,
  setActiveTab,
  setPollModalActive,
  setPollResultLoading,
} from '../../redux/slices/currentInterviewSlice';
import {
  getTemplateConfig, INTRODUCTION_SHEET,
} from '../../configs/interviewConfigs';
import {
  CHOICE_TYPES, INTERVIEW_TABS_KEYS, QUESTION_KEYS, TEMPLATE_TYPES,
} from '../../constants/names';
import { setChoicesThunk } from '../../redux/thunks/interviewThunk';
import { setAlert } from '../../redux/slices/alertSlice';
import { useInterviewSideEffects } from './hooks/interview.hooks';
import PollModal from '../../components/PollModal/PollModal';
import {
  closePolling, getVotesPolled, getPollResults, initiatePolling, openPolling, getClientsConnected,
} from '../../redux/thunks/eventThunk';
import { useTranslation } from '../../context/LanguageProvider';

const { INTRODUCTION, SURVEY } = INTERVIEW_TABS_KEYS;
const {
  CHAPTER_TYPE,
  BANNER_IMAGE,
  POLLING_COUNT,
  POLLING_PERIOD,
} = QUESTION_KEYS;
const {
  RANKING,
  TABLE_VIEW,
  TABLE_VIEW_GROUPS,
  PRESENTATION,
  IMAGE_INTERACTIVE,
} = TEMPLATE_TYPES;
const { SECTION, CASE } = CHOICE_TYPES;

const Interview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useInterviewSideEffects();
  const { isOnlyCase } = useSelector(state => state.event);
  const [ isSummaryPage, setIsSummeryPage ] = useState(false);
  const {
    sheetContent,
    activeTab,
    isNextLoading,
    id: caseId,
    uuid,
    isAllFinished,
    pollingData,
    isPollResultLoading,
    isPollModalActive,
    pollingIsOpen,
  } = useSelector(state => state.currentInterview);
  const [ pollTimer, setPollTimer ] = useState(0);
  const [ isTimerActive, setIsTimerActive ] = useState(false);
  const [ showPollResultFlag, setShowPollResultFlag ] = useState(false);
  const [ isTermsAccepted, setIsTermsAccepted ] = useState(!!localStorage.getItem(`${uuid}_${caseId}_terms`));

  const allTabs = useMemo(() => {
    if (!sheetContent) return [ INTRODUCTION_SHEET ];
    return [
      INTRODUCTION_SHEET,
      ...sheetContent,
    ];
  }, [ sheetContent ]);
  const notStartedTabs = useMemo(() => {
    const tabs = [];
    if (activeTab === SURVEY) {
      sheetContent.forEach(item => item.data.question[CHAPTER_TYPE] !== RANKING
        && !item.started && tabs.push(item.title));
    }
    return tabs;
  }, [ activeTab ]);
  const isFinishDisabled = activeTab === SURVEY && !!notStartedTabs.length;
  const activeTabData = allTabs.find(item => item.title === activeTab);
  const activePollingData = pollingData.find(item => item.title === activeTab);
  const activeTabConfigs = getTemplateConfig(activeTabData);
  const isChapterRanking = activeTabData.data.question[CHAPTER_TYPE] === RANKING;
  const isChapterPhysical = activeTabData.data.question[CHAPTER_TYPE] === IMAGE_INTERACTIVE;
  const isPollingAvailable = activeTabData.data.question[POLLING_COUNT] > 0;
  const pollingPeriod = Number(activeTabData.data.question[POLLING_PERIOD]) || 300;
  const headingInfo = activeTabData || {};
  const headingImage = useMemo(() => getHeadingImage(), [ activeTabData ]);

  const nextButtonText = (!isSummaryPage
    && !!activeTabData?.data?.question?.showSummary
    && activeTabConfigs.summaryText) || 'Next';
  const isNextButtonDisabled = useMemo(() => {
    let isNextButtonFlag = (activeTabConfigs.summaryText
    && !activeTabData.started
      && (activeTabData.data.question[CHAPTER_TYPE] === TABLE_VIEW
        || activeTabData.data.question[CHAPTER_TYPE] === TABLE_VIEW_GROUPS))
      || (activeTab !== INTRODUCTION
      && activeTabData.data.question[CHAPTER_TYPE] !== PRESENTATION
      && activeTabData.data.question[CHAPTER_TYPE] !== RANKING
        && !activeTabData.started);
    if (activePollingData) {
      if (!isOnlyCase) {
        const pollFlag = (activePollingData.pollIds.length > 0
          && activePollingData.started && !activePollingData.finished);
        isNextButtonFlag = isNextButtonFlag || pollFlag;
      }
    }
    return isNextButtonFlag;
  }, [ activePollingData, activeTabData ]);
  const onNextClick = () => {
    if (isFinishDisabled) {
      return dispatch(setAlert({
        message: `${notStartedTabs.join(', ')} tab${notStartedTabs.length > 1 ? 's' : ''} ${notStartedTabs.length > 1 ? "don't" : "doesn't"} have choice`,
        timer: 5000,
      }));
    }
    if (activeTabConfigs.summaryText
      && !isSummaryPage
      && !!activeTabData?.data?.question?.showSummary) {
      return setIsSummeryPage(true);
    }
    if (activeTab === allTabs[allTabs.length - 1].title) {
      return dispatch(setChoicesThunk({
        type: CASE,
      }));
    }
    if (
      (activeTab === INTRODUCTION || activeTabData.finished)
      && activeTabData.data.question[CHAPTER_TYPE] !== RANKING
    ) {
      setIsSummeryPage(false);
      if (!isOnlyCase) {
        const checkNextPolling = pollingData.find(item => item.title === getActiveTab(true));
        if (activeTab === INTRODUCTION && checkNextPolling.pollIds.length === 0) {
          dispatch(setActiveTab(getActiveTab(true)));
          return dispatch(
            initiatePolling({
              chapter: getActiveTab(true),
              id: getActiveTabId(true),
            }),
          );
        }
      }
      return dispatch(setActiveTab(getActiveTab(true)));
    }
    if (!isOnlyCase) {
      dispatch(
        initiatePolling({
          chapter: getActiveTab(true),
          id: getActiveTabId(true),
        }),
      );
    }
    return dispatch(
      setChoicesThunk({
        type: SECTION,
        nextTab: getActiveTab(true),
      }),
    );
  };

  const previousChapter = () => {
    dispatch(setActiveTab(getActiveTab()));
  };

  const showPollResults = () => {
    setShowPollResultFlag(true);
    dispatch(setPollResultLoading(true));
    dispatch(getPollResults(activePollingData));
  };

  const openPoll = async () => {
    setIsTimerActive(true);
    if (!activePollingData?.timer || pollTimer === 0) {
      setPollTimer(pollingPeriod * 1000);
    } else {
      setPollTimer(activePollingData.timer);
    }
    dispatch(resetPollConfig(activeTabData));
    dispatch(openPolling(activePollingData));
    setShowPollResultFlag(false);
    dispatch(setPollModalActive(true));
  };

  const closePoll = () => {
    dispatch(setActivePollTimer({ activeTimer: pollTimer, ...activePollingData }));
    setIsTimerActive(false);
    dispatch(closePolling(activePollingData));
    showPollResults();
  };

  useEffect(() => {
    setIsSummeryPage(false);
    let interval = null;
    let voteMonitor = null;
    let clientConnected = null;
    if (isTimerActive) {
      let pollCounter = pollTimer;
      interval = setInterval(() => {
        if (pollCounter > 0) {
          pollCounter -= 1000;
          setPollTimer(pollCounter);
        } else {
          pollCounter = 0;
          setPollTimer(0);
          closePoll();
          clearInterval(interval);
          clearInterval(voteMonitor);
        }
      }, 1000);
      voteMonitor = setInterval(() => {
        dispatch(getVotesPolled({
          id: activePollingData.id, pollBatchId: activePollingData.pollIds[0],
        }));
      }, 3000);
    } else {
      clearInterval(interval);
      clearInterval(voteMonitor);
    }
    if (activeTab === INTRODUCTION && !isOnlyCase) {
      clientConnected = setInterval(() => {
        dispatch(getClientsConnected(activeTab));
      }, 1000);
    } else {
      clearInterval(clientConnected);
    }
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('beforeunload', handleRefresh);
    return () => {
      clearInterval(interval);
      clearInterval(voteMonitor);
      clearInterval(clientConnected);
    };
  }, [ activeTab, isTimerActive, activePollingData ]);

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const handleKeyPress = (event) => {
    if (!isOnlyCase && event.key === 'F5' && activeTab !== INTRODUCTION) {
      event.preventDefault();
      event.returnValue = 'Unsaved changes will be lost. Do you wish to proceed ?';
    }
  };
  const handleRefresh = (event) => {
    if (!isOnlyCase && activeTab !== INTRODUCTION) {
      event.preventDefault();
      event.returnValue = 'Unsaved changes will be lost. Do you wish to proceed ?';
    }
  };

  return (
    <div className={s.interviewPanel}>
      <div className={s.interview}>
        <div className={classNames(s.interviewHeading, { [s.interviewRanking]: isChapterRanking })}>
          <Heading data={headingInfo} headingImage={headingImage} isRanking={isChapterRanking} />
        </div>
        <div
          className={classNames(s.interviewWrapper, {
            [s.interviewRanking]: isChapterRanking,
            [s.interviewPhysical]: isChapterPhysical,
          })}>
          <div
            className={classNames(
              s.interviewPageContainer,
              activeTabData.data.question[CHAPTER_TYPE] === IMAGE_INTERACTIVE
                ? s.physicalExamInterview
                : s.otherInterview,
            )}>
            <div className={s.interviewPages}>
              {!!activeTabConfigs.Component && (
                <activeTabConfigs.Component
                  isTermsAccepted={isTermsAccepted}
                  setIsTermsAccepted={setIsTermsAccepted}
                  data={activeTabData}
                  isSummaryPage={isSummaryPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={s.interview__shift}>
        <div className={s.interview__modify}>
          {isMobile && isSummaryPage && activeTabConfigs.summaryText && (
            <Button
              prev
              variant='primary'
              className={s.modifyButton}
              onClick={() => setIsSummeryPage(false)}>
              {t('Modify')}
            </Button>
          )}
          {activeTab !== INTRODUCTION && (
            <Button disabled={pollingIsOpen} onClick={previousChapter}>
              {t('Previous')}
            </Button>
          )}
        </div>
        <div className={s.interview_action_group}>
          {!isOnlyCase && activeTab !== INTRODUCTION && !isSummaryPage && isPollingAvailable && (
            <div className={s.pollInfoGroup}>
              <div className={s.pollCountHolder}>
                <div className={s.pollCount}>
                  {activePollingData.noOfVotes} {t('votes')}
                </div>
                <div className={s.pollTimerHolder}>
                  <div
                    className={classNames(s.pollTimer, {
                      [s.pollTimerClosed]: !activePollingData.started,
                    })}>
                    {activePollingData.started ? t('Polling In Progress') : t('Poll Closed')}
                  </div>
                  <Button
                    disabled={activePollingData.started}
                    className={s.openPollButton}
                    onClick={openPoll}
                    variant='light'>
                    {t('Open Poll')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!isOnlyCase && activeTab !== INTRODUCTION && !isSummaryPage && isPollingAvailable && (
            <Button
              variant={'polling'}
              loading={isPollResultLoading}
              disabled={!activePollingData.finished || activePollingData.noOfVotes === 0}
              className={s.modifyButton}
              onClick={() => showPollResults()}>
              {t('Show Poll')}
            </Button>
          )}
          {!isMobile && isSummaryPage && activeTabConfigs.summaryText && (
            <Button
              variant='light'
              className={s.modifyButton}
              onClick={() => setIsSummeryPage(false)}>
              {t('Modify')}
            </Button>
          )}
          <Button
            loading={isNextLoading}
            disabled={isNextButtonDisabled}
            onClick={onNextClick}
            variant={'primary'}>
            {t(nextButtonText)}
            {nextButtonText === 'See Results' ? ' >' : ''}
          </Button>
        </div>
      </div>
      {activeTab !== INTRODUCTION && isPollModalActive && !isAllFinished && (
        <PollModal
          data={headingInfo}
          isRanking={isChapterRanking}
          pollData={activePollingData.results}
          pollTimer={pollTimer}
          showPollResultFlag={showPollResultFlag}
          closePoll={closePoll}
          noOfVotes={activePollingData.noOfVotes}
          onClose={() => dispatch(setPollModalActive(false))}
        />
      )}
    </div>
  );

  function getActiveTab(isPlus) {
    const tabIndex = allTabs.findIndex(item => item.title === activeTab);
    const futureTabIndex = isPlus ? tabIndex + 1 : tabIndex - 1;
    return allTabs[futureTabIndex].title;
  }

  function getActiveTabId(isPlus) {
    const tabIndex = allTabs.findIndex(item => item.title === activeTab);
    const futureTabIndex = isPlus ? tabIndex + 1 : tabIndex - 1;
    return allTabs[futureTabIndex].id;
  }

  function getHeadingImage() {
    if (!activeTabData || activeTabData.title === INTRODUCTION) return '';
    const icon = activeTabData.data.question[BANNER_IMAGE];
    return `${process.env.REACT_APP_IMAGE_URL}images/${icon}`;
  }
};

export default Interview;
