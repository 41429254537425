import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import s from './TableViewGroups.module.scss';
import Category from '../../../../components/UI/Category/Category';
import TableViewResults from './components/TableViewResults/TableViewResults';
import { Checkbox } from '../../../../components/UI';
import { setChoicesThunk } from '../../../../redux/thunks/interviewThunk';
import { CHAPTER_TYPES, CHOICE_TYPES, QUESTION_KEYS } from '../../../../constants/names';
import { useTranslation } from '../../../../context/LanguageProvider';
import LabTemplate from '../../../../components/LabTemplate/LabTemplate';
import NoCategory from '../../../../components/UI/NoCategory/NoCategory';

const { SELECT_ALL } = QUESTION_KEYS;
const { QUESTION_LAB_TEST } = CHAPTER_TYPES;

const TableViewGroups = ({ isSummaryPage, data }) => {
  const dispatch = useDispatch();
  const { t, key_t } = useTranslation();
  const { answers, answersCategories, question } = data.data;
  const isLaboratory = question?.templateType === QUESTION_LAB_TEST;
  const useCategory = question?.useCategory || true;
  const isSelectAllExist = data.data.question[SELECT_ALL] === key_t('YES');
  const [ activeCategories, setActiveCategories ] = useState([]);

  const allCategories = Object.entries(answers);
  const toggleCategory = (name) => {
    if (activeCategories.includes(name)) {
      return setActiveCategories(prev => prev.filter(item => item !== name));
    }
    return setActiveCategories(prev => [ ...prev, name ]);
  };

  const itemsData = useMemo(() => {
    let allItemsLength = 0;
    const selectedItems = [];
    if (Array.isArray(answers)) return { selectedItems, allItemsLength };
    const dataEntries = Object.values(answers);
    dataEntries.forEach((item) => {
      item.forEach((el) => {
        allItemsLength += 1;
        if (el.selected) {
          selectedItems.push(el);
        }
      });
    });
    return { selectedItems, allItemsLength };
  }, [ answers ]);

  const isAllSelected = itemsData.selectedItems.length === itemsData.allItemsLength;

  const onAllClick = () => {
    if (!isAllSelected) {
      setActiveCategories(Object.keys(answers));
    }
    const operation = isAllSelected ? 0 : 1;
    const value = { optionId: 'all' };
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
  };

  useEffect(() => {
    if ((question?.useCategory && question?.expandCategory)) {
      Object.entries(answers).forEach((item) => {
        if (!activeCategories.includes(item[0])) {
          toggleCategory(item[0]);
        }
      });
    }
  }, [ question?.chapterName ]);

  return (
    <section className={s.tableViewSection}>
      {isLaboratory && <LabTemplate />}
      <div className={classNames(s.lab_sheet_body, { [s.labTemplate]: isLaboratory })}>
        {isSummaryPage ? (
          <TableViewResults
            answersCategories={answersCategories}
            selectedItems={itemsData.selectedItems}
          />
        ) : (
          <>
            {isLaboratory
                && <div className={s.lab_sheet_placeholder}>
                  {t('INDICATE TESTS REQUIRED')}</div>}
            {isSelectAllExist && (
              <div
                className={classNames(s.questionSet1__selectAll, s.questionSet1__selectAll_groups)}>
                <div className={s.questionSet1__selectInner}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={() => onAllClick()}
                    title={t(`${isAllSelected ? 'Remove' : 'Select'} All`)}
                  />
                </div>
              </div>
            )}
            <ul className={s.groupList}>
              {useCategory && allCategories.map(category => (
                <Category
                  checkbox
                  key={category[0]}
                  title={category[0]}
                  items={category[1]}
                  isActive={activeCategories.includes(category[0])}
                  toggleCategory={() => toggleCategory(category[0])}
                  checkboxKey={answersCategories[1]}
                  columnWidth={`${question?.columnWidth}` || 200}
                />
              ))}
              {!useCategory && allCategories.map(category => (
                <NoCategory
                  checkbox
                  key={category[0]}
                  title={category[0]}
                  items={category[1]}
                  isActive={activeCategories.includes(category[0])}
                  toggleCategory={() => toggleCategory(category[0])}
                  checkboxKey={answersCategories[1]}
                  columnWidth={`${question?.columnWidth}px` || '200px'}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default TableViewGroups;
