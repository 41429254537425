import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import s from './TableView.module.scss';
import TableViewResults from './components/TableViewResults/TableViewResults';
import DropdownRow from '../../../../components/UI/DropdownRow/DropdownRow';
import { setChoicesThunk } from '../../../../redux/thunks/interviewThunk';
import { CHAPTER_TYPES, CHOICE_TYPES, QUESTION_KEYS } from '../../../../constants/names';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';
import { useTranslation } from '../../../../context/LanguageProvider';
import LabTemplate from '../../../../components/LabTemplate/LabTemplate';

const { SELECT_ALL, COLUMN_WIDTH } = QUESTION_KEYS;
const { QUESTION_LAB_TEST } = CHAPTER_TYPES;

const TableView = ({ isSummaryPage, data }) => {
  const dispatch = useDispatch();
  const { t, key_t } = useTranslation();
  const { question, answers, answersCategories } = data.data;
  const isSelectAllExist = data.data.question[SELECT_ALL] === key_t('YES');
  const isLaboratory = question?.templateType === QUESTION_LAB_TEST;
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  const columnWidth = isMobile ? '100%' : Number(`${question[COLUMN_WIDTH]}`) || 200;
  const [ hasOverflowX, setHasOverflowX ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const groupList = document.querySelector('#groupList');
      if (groupList) {
        setHasOverflowX(groupList.scrollWidth > groupList.clientWidth);
      }
    }, 10);
  }, [ question ]);

  const {
    selectedItems, isNoneSelected, noneId, noneIds,
  } = useMemo(() => {
    const selected_items = [];
    let is_none_selected = false;
    let none_id = -1;
    const exclusivity_ids = [];
    answers.forEach((item) => {
      if (item.selected) {
        selected_items.push(item);
      }
      if (item[answersCategories[0]].includes('***')) {
        if (item.selected) {
          none_id = item.id;
          is_none_selected = item.selected;
        }
        exclusivity_ids.push(item.id);
      }
    });
    return {
      selectedItems: selected_items,
      isNoneSelected: is_none_selected,
      noneId: none_id,
      noneIds: exclusivity_ids,
    };
  }, [ answers ]);

  const onItemClick = (id, isSelected, isNone) => {
    const operation = isSelected ? 0 : 1;
    const value = {
      optionId: id,
      isNone,
    };
    if (!isNone && isNoneSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: noneId },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (noneIds.length < 2 && isNone && id === noneId && !isSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: 'all', isNone: false },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (noneIds.length > 1 && noneIds.includes(id) && !isSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: 'all', isNone: false },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else {
      dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
    }
  };

  const isAllSelected = selectedItems.length === answers.length;

  return (
    <section className={s.tableViewSection}>
      {isLaboratory && <LabTemplate />}
      <div className={classNames(s.lab_sheet_body, { [s.labTemplate]: isLaboratory })}>
        {isSummaryPage ? (
          <TableViewResults
            answersCategories={answersCategories}
            selectedItems={selectedItems}
            noneIds={noneIds} />
        ) : (
          <>
            {isLaboratory
              && <div className={s.lab_sheet_placeholder}>
                {t('INDICATE TESTS REQUIRED')}</div>}
            {isSelectAllExist && <div className={s.questionSet1__selectAll}>
              <div className={s.questionSet1__selectInner}>
                <Checkbox
                  checked={isAllSelected}
                  onChange={() => onItemClick('all', isAllSelected)}
                  title={t(`${isAllSelected ? 'Remove' : 'Select'} All`)}
                  disabled={noneId !== null}
                  isDisabled={noneId !== null}
                  />
              </div>
            </div>}
            <ul
              style={{ flexDirection: hasOverflowX ? 'row' : 'column' }}
              className={classNames(s.groupList,
                { [s.labNoGroup]: isLaboratory && !question?.useCategory })}>
              {answers.map((item) => {
                const isNone = noneIds.includes(item.id);
                const validValue = isNone ? item[answersCategories[0]].split('***')[1] : item[answersCategories[0]];
                return (
                  <DropdownRow
                    columnWidth={columnWidth}
                    key={item.id}
                    value={validValue}
                    isActive={item.selected}
                    isPolling={item.Polling}
                    toggleItem={() => onItemClick(item.id, item.selected, isNone)} />
                );
              })}

            </ul>
          </>
        )}
      </div>
    </section>

  );
};

export default TableView;
