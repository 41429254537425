import { ICONS } from '../assets/images/icons';
import { MENU_IMAGES } from '../assets/images/menu';
import { INTERVIEW_TABS_CHAPTERS, QUESTION_KEYS, TEMPLATE_TYPES } from '../constants/names';
import ImageInteractive from '../pages/Interview/Templates/ImageInteractive/ImageInteractive';
import InterviewTemplate from '../pages/Interview/Templates/InterviewTemplate/InterviewTemplate';
import MultipeChoice from '../pages/Interview/Templates/MultipeChoice/MultipeChoice';
import Presentation from '../pages/Interview/Templates/Presentation/Presentation';
import Ranking from '../pages/Interview/Templates/Ranking/Ranking';
import TableView from '../pages/Interview/Templates/TableView/TableView';
import TableViewGroups from '../pages/Interview/Templates/TableView/TableViewGroups';
import Introduction from '../pages/Introduction/Introduction';

const {
  INTRODUCTION,
} = INTERVIEW_TABS_CHAPTERS;

const {
  IMAGE_INTERACTIVE,
  MULTIPE_CHOICE,
  RANKING,
  TABLE_VIEW,
  INTERVIEW,
  TABLE_VIEW_GROUPS,
  PRESENTATION,
} = TEMPLATE_TYPES;

const {
  CHAPTER_TYPE, ICON, MENU_IMAGE, CHAPTER_NAME,
} = QUESTION_KEYS;

const TEMPLATE_CONFIGS = {
  [INTRODUCTION]: {
    icon: 'Introduction',
    Component: Introduction,
  },
  [INTERVIEW]: {
    Component: InterviewTemplate,
    summaryText: 'See Summary >',
  },
  [TABLE_VIEW]: {
    Component: TableView,
    summaryText: 'See Results',
  },
  [TABLE_VIEW_GROUPS]: {
    Component: TableViewGroups,
    summaryText: 'See Results',
  },
  [MULTIPE_CHOICE]: {
    Component: MultipeChoice,
    summaryText: 'See Summary >',
  },
  [IMAGE_INTERACTIVE]: {
    Component: ImageInteractive,
  },
  [RANKING]: {
    Component: Ranking,
    summaryText: 'See Summary >',
  },
  [PRESENTATION]: {
    Component: Presentation,
  },
};

export function getTemplateConfig(sheet) {
  return TEMPLATE_CONFIGS[sheet.data.question[CHAPTER_TYPE]] || {};
}

export const INTRODUCTION_SHEET = {
  title: INTRODUCTION,
  visited: true,
  data: {
    question: {
      [CHAPTER_NAME]: INTRODUCTION,
      [CHAPTER_TYPE]: INTRODUCTION,
      [ICON]: ICONS.introductionIcon,
      [MENU_IMAGE]: MENU_IMAGES[INTRODUCTION],
    },
  },
};
