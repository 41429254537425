import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
// import { ShortenUrlProvider } from 'react-shorten-url';
import s from './Header.module.scss';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { resetCaseThunk } from '../../redux/thunks/eventThunk';
import { selectInterviewTranslationId } from '../../redux/slices/currentInterviewSlice';
import { getisInterviewPage } from '../../helpers/utils';
import { selectEvent } from '../../redux/slices/eventSlice';
import { useTranslation } from '../../context/LanguageProvider';
import { Button } from '../UI';
// import medqpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import welcomeIcon from '../../assets/images/welcome.svg';

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const caseId = useSelector(selectInterviewTranslationId);
  const event = useSelector(selectEvent);
  const [ isConfirmActive, setIsConfirmActive ] = useState(false);

  const isInterviewPage = getisInterviewPage(pathname);
  const isStartOverVisible = isInterviewPage
    && caseId;

  const openConfirm = () => {
    setIsConfirmActive(true);
  };

  const startOver = () => {
    dispatch(resetCaseThunk({ caseId })).unwrap().then(() => setIsConfirmActive(false));
  };

  return (
    <header className={s.header}>
      <div className={s.headerInfo}>
        {isStartOverVisible && !event.isOnlyCase && (
          <div className={s.headerStart}>
            <Button onClick={openConfirm} variant='grey'>
              {t('Start Over')}
            </Button>
          </div>
        )}
        {event.id && !event.isOnlyCase && isInterviewPage && (
          <section className={s.headerEventInfo}>
            <div className={s.headerText}>
              <p className={s.headerText__eventName}>{event.name}</p>
              <p className={s.headerText__eventDetails}>
                {event.city && `${event.city}, `}
                {event.country} - {dayjs(event.startDate).format('MMM D, YYYY')}
              </p>
            </div>
          </section>
        )}
      </div>
      <div className={s.headerInfo}>
        <div className={s.headerPharmaceutical}>
          <div
            className={classNames(s.headerPharmaceutical__icon, {
              [s.header_custom_logo_bi]: !event?.isOnlyCase && event && event?.eventLogo,
            })}>
            {!event?.isOnlyCase && event && event?.eventLogo ? (<img
              className={s.welcome__img}
              src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventLogo}`}
              alt='BoehringerIngelheim'
            />) : (
              <img className={s.welcome__img} src={welcomeIcon} alt='Boehringer Ingelheim' />
            )}
          </div>
        </div>
      </div>
      {/* MODAL */}
      {isConfirmActive && (
        <ConfirmModal
          onConfirm={startOver}
          onClose={() => setIsConfirmActive(false)}
          loading={event.isStartOverLoading}
        />
      )}
    </header>
  );
};

export default Header;
