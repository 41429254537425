import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import Human from '../../../../../../components/Human/Human';
import {
  HUMAN_POSITION, HUMAN_SIDES, PHYSICAL_ITEM_IDS,
} from '../../../../../../constants/physicalConstants';
import { useTranslation } from '../../../../../../context/LanguageProvider';
import s from '../../ImageInteractive.module.scss';

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const { BOTH } = HUMAN_SIDES;
const { BACK, FRONT } = HUMAN_POSITION;
const {
  FRONT_BACK, EXAM, OBSERVATION, SIDE, IMAGE,
} = PHYSICAL_ITEM_IDS;

const PhysicalInfo = ({
  zoom, side, activeNavData, onPhysicalClick, openImage, getPhotoPaths,
  lastClicked, lastClickedRef, physicalCategories,
}) => {
  const { t } = useTranslation();
  const isBack = side === 'Back';
  const listRef = useRef(null);

  const currentSideData = useMemo(() => {
    const position = isBack ? BACK : FRONT;
    return activeNavData.filter(el => el[physicalCategories[FRONT_BACK]] === position);
  }, [ activeNavData ]);

  const currentSideSelectedData = useMemo(() => (
    currentSideData.filter(item => item.selected).sort((a, b) => a.order - b.order)
  ), [ currentSideData ]);

  const isTablet = useMediaQuery({
    query: '(max-width: 1366px)',
  });

  useEffect(() => {
    if (lastClicked && listRef.current) {
      listRef.current.scrollTo({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
    lastClickedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [ lastClicked, listRef ]);

  return (
    <div className={s.physical__info}>
      <div className={s.physical__skeleton}>
        {!isTablet && (
        <div className={s.physical__side}>{t(`${side} Side`)}</div>
        )}
        <Human
          zoom={zoom}
          back={isBack}
          data={currentSideData}
          order={currentSideSelectedData[currentSideSelectedData.length - 1]?.order + 1 || 1}
          onHumanPartClick={onPhysicalClick}
          physicalCategories={physicalCategories} />
      </div>
      <div className={s.physical__partInfo} ref={listRef}>
        <ul className={s.physical__partList}>
          {
          currentSideSelectedData.map((item) => {
            const currentImage = item[physicalCategories[IMAGE]]
            && getPhotoPaths(item[physicalCategories[IMAGE]]);
            return (
              <li
                key={item.id}
                ref={lastClicked === item.id ? lastClickedRef : null}
                className={classNames(s.physical__partItem,
                  { [s.pollingItem]: item.Polling, [s.imageItem]: currentImage })}>
                <div className={s.physical__partName}>{`${item[physicalCategories[SIDE]] !== BOTH ? `${t(item[physicalCategories[SIDE]])} ` : ''}${t(item[physicalCategories[EXAM]])}`}:</div>
                <div className={s.physical__partText}>
                  {item[physicalCategories[OBSERVATION]]?.split('\n').map((paragraph, i) => (
                    <p key={i} className='paragraph'>{paragraph}</p>
                  ))}
                </div>
                {currentImage
                && <div
                  onClick={() => openImage(item[physicalCategories[IMAGE]])}
                  className={s.physical__partImage}>
                  <picture>
                    <source srcSet={imageUrl + currentImage.webp} type='image/webp' />
                    <source srcSet={imageUrl + currentImage.mozJpeg} type='image/jpeg' />
                    <img src={imageUrl + currentImage.mozJpeg} alt='' />
                  </picture>
                </div>}
              </li>);
          })
        }
        </ul>
      </div>
    </div>
  );
};

export default PhysicalInfo;
